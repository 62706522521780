@import-normalize;
@import '@font/nunito';

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  min-width: 320px;
}

a {
  text-decoration: none;
  color: inherit;
}
